import { ChakraProvider, extendTheme } from '@chakra-ui/react';
import {
  Links,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  json,
  useRouteError,
  useRouteLoaderData,
} from '@remix-run/react';

import { ChakraThemeProvider } from '~/design-system/useChakraTheme';
import { GenericErrorBoundary } from './ui-components/GenericErrorBoundary';
import { GlobalErrorDialogProvider } from './ui-components/GlobalErrorDialog/GlobalErrorDialogProvider';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { STAGE } from './stage';
import { captureRemixErrorBoundaryError } from '@sentry/remix';
import { useState } from 'react';

export async function loader() {
  return json({
    BENV: {
      STAGE,
    },
  });
}

export function Layout({ children }: { children: React.ReactNode }) {
  const data = useRouteLoaderData<typeof loader>('root');
  return (
    <html lang='en'>
      <head>
        <link rel='preconnect' href='https://www.google.com' />
        <link
          rel='preconnect'
          href='https://www.gstatic.com'
          crossOrigin='anonymous'
        />
        <Meta />
        <meta charSet='UTF-8' />
        <meta name='viewport' content='width=device-width,initial-scale=1' />
        <title>Navace</title>
        <Links />
      </head>
      <body>
        <script
          suppressHydrationWarning
          dangerouslySetInnerHTML={{
            __html: `window.BENV = ${JSON.stringify(data?.BENV)}`,
          }}
        />
        {children}

        <Scripts />
        <ScrollRestoration />
      </body>
    </html>
  );
}

export default function App() {
  const customTheme = extendTheme({
    styles: {
      global: {
        body: {
          height: '100vh',
          background: '#f3f3f3',
          color: '#2d3748',
          fontWeight: '500',
        },
        'ul, ol': {
          listStyle: 'none',
        },
      },
    },
    components: {
      Drawer: {
        sizes: {
          xl: {
            dialog: {
              maxW: '80vw',
              maxH: 'calc(100vh - 20px)',
              top: '10px !important',
              bottom: '10px !important',
              right: '10px !important',
              borderRadius: '10px',
            },
          },
        },
      },
      Modal: {
        sizes: {
          '8xl': {
            dialog: {
              maxW: 'calc(100vw - 20px)',
              maxH: 'calc(100vh - 20px)',
            },
          },
        },
      },
    },
    textStyles: {
      default: {
        lineHeight: '1.1rem',
      },
    },
  });

  const [queryClient] = useState(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            staleTime: 1000,
            gcTime: 0,
            refetchOnMount: 'always',
            refetchOnWindowFocus: false,
          },
        },
      })
  );

  return (
    <ChakraProvider theme={customTheme}>
      <ChakraThemeProvider>
        <QueryClientProvider client={queryClient}>
          <GlobalErrorDialogProvider>
            <Outlet />
          </GlobalErrorDialogProvider>
        </QueryClientProvider>
      </ChakraThemeProvider>
    </ChakraProvider>
  );
}

export function ErrorBoundary() {
  const error = useRouteError();

  captureRemixErrorBoundaryError(error);

  return (
    <ChakraProvider>
      <GenericErrorBoundary />
    </ChakraProvider>
  );
}

export const shouldRevalidate = () => false;
